import axios from "axios";

export const homeBannerApi = async (endpoint) => {
  try {
    const response = await axios.get(
      `https://miracleinside.info/wp-json/wp/v2/homeslider/${endpoint}`
    );
    return response.data;
  } catch (error) {
    return -1;
  }
};

