import React, { Component } from "react";
import About from "./About/About";
import Feature from "./Features/Feature";
import Services from "./Services/Services";
import Testimonials from "./Testimonials/Testimonials";
import Blog from "./Blog/Blog";
import Footer from "./Footer/Footer";
import SliderInner from "../SliderInner/SliderInner";
import Backgroundimg from "../../images/sliders/homeposter.JPG";
import Metatags from "../MetaTags/Metatags";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { metaDataApi } from "../../utils/metaDataApi";
import { homeBannerApi } from "../../utils/homeBannerApi";
import Modalwindow from "./ModalWindow/Modalwindow";


export class Home extends Component {
constructor(props) {
    super(props);
    this.state = {
      metaData: {
        name: "",
        description: "",
      },
      banner:{
        url: ""
      }
    };
  }
  async componentDidMount() {
    try {
      const response = await metaDataApi(145);
      const bannerresponse = await homeBannerApi(461);
console.log("meta data : ", response.acf);

      if (response !== -1) {
        this.setState({
          metaData: {
            name: response.acf.meta_title,
            description: response.acf.meta_description,
            meta_keyword:response.acf.meta_keyword
          },
        });
      }

      if (bannerresponse !== -1) {
        this.setState({
          banner: {
            url: bannerresponse.acf.slider_image_1
          },
        });
      }


    } catch (error) {
      console.error("Error fetching metadata:", error);
    }
  }
  render() {
    const { name, description,meta_keyword  } = this.state.metaData;

    const { url } = this.state.banner;


    return (
      <>
        <HelmetProvider>
          <Helmet>
            <link rel="canonical" href="https://miracleinside.com/" />
          </Helmet>
        </HelmetProvider>

        <Metatags title={name} description={description} keyword={meta_keyword} />
        <SliderInner image={url} />
        <About />
        <Feature />
        <Services />
        <Testimonials />
        <Modalwindow/>
        <Blog />
        <Footer />
      </>
    );
  }
}

export default Home;

